$column-gutter: 0;


.drag-hero__item {
	// background: #C09
	float: left;
	color: #F8F8F8;
	text-align: center;
	background: transparent !important;
	// border: 4px solid transparent
	// &:hover
	// 	border-color: hsla(0, 0%, 100%, 0.5)
	/// background: #C90
	&.is-dragging {
		border-color: hsla(0, 0%, 100%, 0.5);
		// background: #C90
		z-index: 2; }
	&.is-positioning-post-drag {
		z-index: 2; } }

.drag-hero__grid .packery-drop-placeholder {
	border: 3px dashed hsla(0, 0%, 0%, 0.5);
	transition: transform 0.2s; }
.drag-hero__grid {
	background-color: #000; }
.HeroContent {
	// background-color: transparent !important
	position: relative;
	// border-style: solid
	// border-width: 2px
	&--wrap {
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		left: 0; }
	&:before {
		width: 100%;
		content: '';
		display: block; }
	&--actions {
		background-color: rgba(0, 0, 0, 0.28);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 30px;
		width: 100%; }

	&--actionsitem {
		@include font-size(24);
		cursor: pointer; }
	&:hover {
		.GridItemPicture {
			img {
				transform: scale(1.1) translate(-50%, -50%);
				transition: transform .2s ease; } } }
	&--front {
		h3 {
			margin: 0;
			z-index: 80;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			text-align: center;
			word-break: break-all;
			width: 100%;
			font-weight: normal; }
		&-small {
			@include font-size(32); }
		&-medium {
			@include font-size(56); }
		&-large {
			@include font-size(72); }
		.video-link {
			position: absolute !important;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0; } }



	&:hover {
		.TeamMember {
			opacity: 1;
			transition: opacity .2s ease; } } }

.TeamMember {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 9;
	top: 0;
	left: 0;
	opacity: 0;
	right: 0;
	top: 0;
	height: 100%;
	width: 100%;
	transition: opacity .2s ease;
	&--content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: left;
		padding: 30px; }
	&--name {
		@include font-size(25);
		font-weight: bold;
		margin-bottom: 10px;
		max-width: 220px; }
	&--title {
		@include font-size(16);
		margin: 0 0 5px 0; }
	&--text {
		margin-bottom: 30px; }
	&--social {
		.icon {
			color: #fff;
			transform: scale(0.8);
			transition: opacity .2s ease;
			&:hover {
				opacity: 0.8;
				transition: opacity .2s ease; } } } }


.drag-hero__item--dash, .drag-hero__grid__dash-grid-sizer {
		width: 25%; }
@for $width from 1 through 4 {
	.drag-hero__item--dash.width#{$width} {
		width: 100% / $width; }
	@for $height from 1 through 4 {
		.drag-hero__item--dash {
			&.height#{$height}.width#{$width} {
				.HeroContent:before {
					padding-top: 79% * $height / $width; } } } } }



.drag-hero__item--dash.width1 {
	width: 25%; }

.GridSettings {

	position: absolute;
	top: 0;
	transform: translateY(-100%);
	background-color: #e7eaec;
	right: 10px;
	width: 80%;
	color: #000;
	display: block;
	width: 100%;
	padding: 15px;
	cursor: auto;
	border-radius: 3px;
	z-index: 99999;
	line-height: 1;
	&--item {
		width: 100%;
		float: left;
		cursor: pointer;
		text-align: center;
		&-colors {
			float: left;
			width: 25%;
			height: 30px; }
		&-icons {
			display: inline-block;
			color: #5c5c5c;
			@include font-size(34);
			height: 50px;
			line-heigh: 50px;
			&.active {
				color: #3a94e0; } } } }

.GridItemPicture {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;
	img {
		min-height: 100%;
		position: absolute;
		max-width: 120%;
		top: 50%;
		left: 50%;
		transform: scale(1) translate(-50%, -50%);
		transition: transform .2s ease; } }

@each $name, $color in $colors {
	.drag-hero__item-#{$name} {
		background-color: $color; }
	.Hero-font.Hero-image.drag-hero__item-#{$name} {
		.HeroContent--front {
			h3 {
				color: $color; } } }
	.GridSettings--item-colors-#{$name} {
		background-color: $color; } }
@media (max-width: $screen-xs-max) {
	.video-link {
		outline: 0; }
	.GridItemPicture {
		img {
			max-height: 100%; } }
	.drag-hero__grid {
		height: auto !important; }
	.drag-hero__item {
		width: 100% !important;
		position: relative !important;
		top: 0 !important;
		left: 0 !important; } }
@media (min-width: $screen-xs-max) and (max-width: 769px) {
	.TeamMember--text {
		display: none; } }

@media only screen and (min-device-width: 768px) and (max-device-width : 1024px)  and (orientation : landscape) {
	.HeroContent--front-medium {
		font-size: 30px; }
	.TeamMember--text {
		display: none; } }

// @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape)
// 	.drag-hero__item
// @media (max-width: $screen-xs-max) and (orientation: landscape)
// 	.HeroContent
// 		max-width: 400px
// 		margin: auto
