.owl-wrapper-outer {
	overflow: hidden; }

.StartTeaser {
	height: 100vh;
	width: 100%;
	overflow: hidden;
	background-color: $brand-color;
	position: relative;
	.welcometitle {
		font-size: 55px;
		color: #fff;
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
		margin: 0;
		margin-bottom: 0px;
		font-weight: bold;
		letter-spacing: 1px;
		line-height: 55px; }

	h3 {
		@include font-size(20);
		color: #fff;
		line-height: 1.3;
		margin-bottom: 20px;
		line-height: 28px;
		margin-top: 15px; }

	&--claim {
		@include font-size(19);
		color: #fff;
		line-height: 1.1;
		margin: 10px 0 20px 0; }
	.btn-outline {
		border-color: #fff;
		color: #fff;
		&:first-of-type {
			margin-right: 10px; }
		&:hover {
			color: $brand-color;
			background-color: #fff;
			transition: all .2s ease; } }
	video {
		position: absolute;
		top: 50%;
		left: 50%;
		pointer-events: none;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		z-index: -15;
		-ms-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		// background: url(../images/Background.jpg) no-repeat;
 } }		// background-size: cover;
.ClientSlider {
	margin: 30px 0;
	height: 100px;
	width: 100%;
	.container-fluid {
		padding: 0; }
	&--carousel {
		display: block;
		width: 100%;
		overflow: hidden; }
	&--carouselwrapper {
			// -webkit-transform-style: preserve-3d;

			// animation: carousel 90s linear infinite;
			white-space: nowrap;
			animation-direction: reverse;
 } }			// transition: transform 60s linear


.Client {
	margin: auto;
	position: relative;
	padding: 0 60px;
	height: 100px;
	display: inline-block;
	&--tablewrap {
		display: table;
		height: 100%;
		width: 100%; }
	&--wrap {
		text-align: center;
		height: 100%;
		display: table-cell;
		vertical-align: middle; } }
@media (max-width: $screen-xs-max) {
	.Client {
		padding: 0 30px; } }

.FancyHeading {}


.panel {
	background-color: transparent;
	box-shadow: none;
	border: 0;
	text-align: left;
	padding: 20px 0 20px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.22); }
.panel-body {
	padding-right: 50px; }
.LogoList--item {
	padding: 8px 15px;
	display: inline-block; }
.panel-site {
	.LogoList--item:nth-of-type(2) img {
		margin-top: 9px; } }
.LogoList--image {
	width: 60%; }
.FancyHeading {
	margin-top: 100px;
	h2 {
		color: #fff;
		word-break: break-word;
		@include font-size(45); }
	.panel-body a {
		font-weight: bold;
		color: #fff; } }

.panel-title {
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	@include font-size(24);
	position: relative;
	a {
		// font-weight: bold
		text-decoration: none !important; } }
.panel-icon {
	right: 0;
	font-size: 50px;
	transform: rotate(180deg);
	height: 50px;
	cursor: pointer;
	color: #fff;
	margin-left: 10px;
	line-height: 50px;
	transition: transform .2s ease; }
.collapsed .panel-icon {
	transform: rotate(0);

	transition: transform .2s ease; }
.TextList {
	color: #fff;
	&__title {
		@include font-size(28);
		margin-top: 1.2em;
		font-style: italic;
		word-wrap: break-word;
 } }		// font-weight: bold
.FancyHeading:before {
	width: 100%;
	height: 156px;
	float: left;
	position: relative;
	background-image: url("/images/blue_background.png");
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	margin-top: -156px;
	content: ''; }
.FancyHeading {
	margin-top: 150px !important;
	background-color: #399cc0; }
.FancyHeading {
	h2 {
		padding: 20px 0 20px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.22); } }
.LogoList--carousel {
	margin-bottom: 30px; }
.panel-site {
	.Software--info {
		margin-bottom: 20px; } }
.FancyBar {
	pointer-events: none;
	width: 3000px;
	background-color: #2d96bd;
	display: block;
	z-index: 1;
	position: absolute;
	height: 440px;
	z-index: -10;
	top: 0;
	left: -50%;
	&:nth-of-type(1) {
		transform: rotate(4deg);
		opacity: 0.16; }
	&:nth-of-type(2) {
		transform: rotate(3deg);
		opacity: 0.9; }
	&:nth-of-type(3) {
		transform: rotate(-5deg);
		opacity: 0.30; } }
.VideoTeaser {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 0;
	&--overlay {
		background-color: #333;
		opacity: 0.8;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		// box-shadow: inset 00px 00px 900px #642d64;
		z-index: 10;
		background-color: #9c3489; } }

.home {
	// .SiteHeader
 }	//  background-color: transparent !important
.TeaseDirection {
	position: absolute;
	bottom: 15px;
	width: 100%;
	text-align: center;
	color: #fff;
	transform: scale(0.8);
	&:hover, &:active, &:focus {
		color: #fff; } }

.VectorLogo {
	width: 200px;
	display: block;
	margin: auto;
	padding-top: 15px;
	svg {
		path, & rect {
			fill: #fff; } } }
.home {
	.SiteLogo {
		display: none; } }
.home {
	.NewsWidget.NewsWidget-closed {
		transform: translateY(100%); }
	&[data-active-section=start] {
		.NewsWidget.NewsWidget-closed {
			transform: translateY(calc(100% - 80px)); } } }

.StartTeaser {
	&--logo {
		text-align: center;
		line-height: 61px;
		height: 61px;
		width: 100%;
		position: relative;
		svg {
			width: 259px;
			position: absolute;
			top: 50%;
			height: 40px;
			left: 50%;
			transform: translateY(-50%) translateX(-50%); } } }
.Tease--wrap {
	margin-top: 40.4%;
	transform: translateY(-50%); }
.row-centered {
	text-align: center; }

.col-centered {
	display: inline-block;
	float: none;
	/* reset the text-align */
	// text-align: left
	/* inline-block space fix */
	margin-right: -4px; }

.scroller {}


.video-link {
	position: relative;
	.video-icon {
		transition: opacity .3s ease; }
	&:hover .video-icon {
		opacity: 0.6;
		transition: opacity .3s ease; } }
.anchor {
	display: block;
	position: relative;
	top: -59px;
	visibility: hidden;
	z-index: -999999; }
.Page {
	&--title {
		@include font-size(36);
		margin-bottom: 100px; }
	&--content {
		margin-bottom: 100px;
		.imprint-table {
			width: 100%;
			td:nth-child(1) {
				min-width: 200px; } } } }
.waypoint {
	position: relative; }

.modal-footer {
	border-top: 0;
	padding-top: 0;
	padding-right: 0; }
// .modal-content
// .modal
//  text-align: center
//  padding: 0 !important

.modal-header {
	border: 0;
	padding: 15px 0; }
.modal-header .close {
	margin-top: 0px;
	cursor: pointer;
	float: right;
	display: inline-block;
	text-align: center; }
.modal-body {
	padding-bottom: 0;
	padding-top: 0;
	.ContactForm {
		margin-top: 0; }
	.ContactForm--item {
		margin-bottom: 5px; } }

.modal-content {
	box-shadow: none;
	border: 0;
	padding: 0;
	border-radius: 3px; }
.modal-title {
	line-height: 1.1em; }

// .modal:before
//  content: ''
//  display: inline-block
//  height: 100%
//  vertical-align: middle
//  margin-right: -4px
// .modal-dialog
//  display: inline-block
//  text-align: left
//  vertical-align: middle
//
@media (max-width: $screen-xs-max) {
	.VideoTeaser {
		background-image: url('../images/startseite_mobile.jpg');
		background-repeat: no-repeat;
		background-size: cover; } }

@media (max-width: $screen-xs-max)  , (orientation: landscape)  and (max-device-width: $screen-xs-max) {
	.LogoList--item {
		padding: 20px 0; }
	.panel-title {
		// padding-right: 50px
		@include font-size(18);
		font-weight: bold; }
	.panel-body {
		padding-right: 0; }
	.modal-title {
		line-height: 1.1em;
		@include font-size(16);
		padding-right: 48px; }
	.modal-dialog {
		margin: 0; }
	.modal-content {
		min-height: 100vh; }
	.modal-body, .modal-header, .modal-footer {
		padding: 10px; }
	.modal-footer {
		padding-top: 0; }




	td, th {
		width: 100%;
		display: block; }
	.Page--content .imprint-table td:nth-child(1) {
		font-weight: bold;
		@include font-size(18); }
	.Page--content .imprint-table tr {
		display: block;
		margin-bottom: 10px; } }
.cc_container {
	font-family: "ProximaNova-Regular", Helvetica, Roboto, Arial, sans-serif !important;
	font-size: 0.9em !important; }
.cc_more_info {
	color: #fff !important;
	font-weight: bold; }
.cc_container .cc_btn, .cc_container .cc_btn:visited {
	max-width: none !important;
	width: auto !important;
	background-color: transparent !important;
	border: 1px solid #fff !important;
	color: #fff !important;
	border-radius: 3px !important;
	font-weight: normal !important;
	&:hover {
		color: $brand-color !important;
		background-color: #fff !important; } }
.request-demo {



	.ContactForm--label {
		display: block;
		text-align: left;
		padding: 3px 0px 4px 0px;
		color: #47494a; } }
@media (max--device-width: $screen-xs-max)  , (orientation: landscape)  and (max-device-width: $screen-xs-max) {
	.ClientSlider--carouselwrapper {
		animation: carousel 30s linear infinite !important;
		animation-direction: reverse !important;
		-webkit-animation: carousel 30s linear infinite !important;
		-webkit-animation-direction: reverse !important; } }
@media (max-width: $screen-xs-max)  , (orientation: landscape)  and (max-device-width: $screen-xs-max) {

	.StartTeaser video {
		display: none; }
	.request-demo {
		h2 {
			font-size: 5.8rem; } }

	.StartTeaser h3, .StartTeaser .welcometitle {
		display: none; }
	.StartTeaser .btn-outline {
		width: 60% !important;
		margin-right: 0 !important;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 10px;
		margin-top: 50px; }
	.Tease--wrap {
		position: absolute;
		transform: translateY(50%);
		margin-top: 0;
		width: 100%;
		right: 0;

		left: 0;
		top: 50%; }
	.StartTeaser--claim {
		text-align: center;
		margin-top: 5px;
		padding: 0 15px;
		margin-bottom: 0; }
	.StartTeaser .btn-outline {
		margin-top: 20px; }
	.StartTeaser .col-xs-12 {
		margin: 0; }
	.StartTeaser--c2a {
		text-align: center; } }


@media (min-width: $screen-md-min) and (max-width: 1600px) {
	// html
 }	//  font-size: 9px
@media (min-width: $screen-md-min) and (max-width: 1600px) {
	// .container
	//  width: 100% !important
 }	//
.SiteLogo--title {
	display: block; }
[data-active-section=start] {
	.SiteLogo--title {
		display: none; } }
@media (max-width: $screen-xs-max) {
	.LanguageBarChooser {
		display: none; }
	.home {
		.LanguageBarChooser {
			display: block; } }
	.TeaseDirection {
		display: none; }
	.StartTeaser .btn-outline {
		margin-top: 50px; }
	.StartTeaser--claim {
		margin-top: 20px; } }
@media (orientation: landscape)  and (max-device-width: $screen-xs-max) {
	.NewsWidget {
		display: none; }
	.TeaseDirection {
		display: none; }
	.Tease--wrap {
		transform: translateY(27%);
		width: 100%; } }
.modal {
	.ThanksMessage {
		padding-bottom: 35px; } }
@media (min-width: 768px) {
	.section-portfolio {
		.Imagebox {
			&:nth-of-type(3) {
				margin-top: 60px !important; }
			&:nth-of-type(4) {
				margin-top: 60px !important; }
			&:nth-of-type(5) {
				margin-top: 60px !important; } } }
	.modal-dialog {
 }		// margin-top: 100px
	// .modal-header
	//  padding-top: 50px
	.modal-header .close {
 }		// margin-top: 30px
	.modal-header h4 {
		font-size: 21px; }
	.modal {
		text-align: center; }
	.modal:before {
		display: inline-block;
		vertical-align: middle;
		content: " ";
		height: 100%; }
	.modal-dialog {
	  display: inline-block;
	  text-align: left;
	  vertical-align: middle; } }

@media (orientation: portrait) and (min-device-width: 480px) and (max-device-width: 800px) {
	.Tease--wrap {
		transform: none; }
	.StartTeaser {
		.text-left.col-xs-12 {} } }
@keyframes carousel {
	0% {
		opacity: 0; }
	4% {
		opacity: 1; }
	99% {
		opacity: 1; }
	100% {
		transform: translateX(0%);
		opacity: 0; } }
@-webkit-keyframes carousel {
	0% {
		opacity: 0; }
	4% {
		opacity: 1; }
	99% {
		opacity: 1; }
	100% {
		-webkit-transform: translateX(0%);
		opacity: 0; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.SiteLogo {
		display: block; }
	.SiteLogo--picture {
		height: 40px; } }
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
	.StartTeaser .btn-outline {
		margin-top: 10px !important; } }

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }



@media (min-width: 767px) {
 	.linktext-mobil {
 		display: none !important; } }

@media only screen and (min-width: 1024px) {
  .Topimage {
    width:83.3333% !important {}
    max-width:none !important {} } }

@media only screen and (max-width: 1024px) {
  .Topimage {
    width:100% !important {}
    max-width: 100% !important;
    > div {
      border: 0 !important; } } }
