.SiteWrapper {
	padding-top: 61px;
	&.home {
		padding-top: 0; } }


.display-table {
	height: 100%;
	width: 100%;
	display: table; }
.vertical-center {
	display: table-cell;
	vertical-align: middle; }
$colors: ('violet': $brand-color, 'violet-light': $brand-color-alt, 'blue': $brand-blue-alt,'red': $brand-red, 'violet-dark': $brand-color-dark, 'white': #fff, 'black': #000);

@each $name, $color  in $colors {
	.page-color--#{$name}, .section-color--#{$name} {
		.cc_container {
			background-color: $color; }
		.cc_container .cc_btn:hover {
			color: $brand-color; }
		.page-nav-inverted-none {
			.SiteHeader--lowlay {
				background-color: $color; } }

		li a {
			color: $color;
			font-weight: bold;
			&:hover {
				color: $color; } }

		.dynamic-background {
			background-color: $color; }
		.blog-post-hero-text.dynamic-background {
			background-color: rgba($color, 0.8); }
		.dynamic-border {
			border-color: $color;
			&.btn-outline {
				color: $color; } }
		.dynamic-hover {
			&.btn-primary {
				border-color: $color;
				background-color: transparent;
				color: $color; }
			&.btn.btn-primary:hover {
				background-color: $color;
				color: #fff; }

			&.btn.btn-naked {
				background-color: transparent;
				color: $color;
				border-color: transparent;
				&:hover {
					background-color: transparent;
					border-color: transparent;
					color: $color; } } }
		.dynamic-color {
			color: $color !important; }
		a.dynamic-hover:hover {
			color: $color; }
		.dynamic-hover.icons {
			a {
				color: #000;
				&:hover {
					color: $color; } } }
		ul li:before {
			color: $color; }
		.DeviceSlider {
			.owl-theme .owl-controls .owl-page span {
				background-color: $color; } }
		.page-nav-inverted-inverted {
			.SiteHeader--lowlay {
				color: $color;
				background-color: #fff; }
			.SiteLogo--picture svg path, .SiteLogo--picture svg g {
				fill: $color; }
			.SiteHeader--navigationButton .icon, .SiteLogo--title, .LanguageBarChooser a {
				color: $color; }
			.LanguageBarChooser a {
				&:after {
					background-color: $color; } } } }
	.project-#{$name} {
		background-color: $color !important;
		.ProjectMeta {
			background-color: rgba($color, .78); } }

	.page-nav-#{$name} {
		.SiteHeader--lowlay {
			background-color: $color !important; }
		&-inverted {
			.SiteHeader--lowlay {
				color: $color !important;
				background-color: #fff !important; }
			.SiteHeader--navigationButton .icon, .SiteLogo--title, .LanguageBarChooser a {
				color: $color !important; }
			.SiteLogo--picture svg path, .SiteLogo--picture svg g {
				fill: $color; }
			.LanguageBarChooser a {
				&:after {
					background-color: $color !important; } } } } }
.wrap {
	width: 100%;
	float: left; }

.bg-grey {
	background-color: #eee; }
.FooterBar {
	.IconList--item .icon {
		color: #47494a; }
	.SocialNav--title {
		color: #47494a; } }

@mixin placeholder {
  	::-webkit-input-placeholder {
  		@content; }
  	:-moz-placeholder {
   		@content; }
  	::-moz-placeholder {
  		@content; }
  	:-ms-input-placeholder {
  		@content; } }




.show {
  display: block; }

.hidden {
  display: none; }

