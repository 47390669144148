@charset "UTF-8";

@font-face {
	font-family: "official-db";
	src: url("../fonts/official-db.eot");
	src: url("../fonts/official-db.eot?#iefix") format("embedded-opentype"), url("../fonts/official-db.woff") format("woff"), url("../fonts/official-db.ttf") format("truetype"), url("../fonts/official-db.svg#official-db") format("svg");
	font-weight: normal;
	font-style: normal; }


[data-icon]:before {
	font-family: "official-db" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "official-db" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }


.icon-xing:before {
	content: "\61"; }

.icon-plus:before {
	content: "\62"; }

.icon-phone:before {
	content: "\63"; }

.icon-pfeil-unten:before {
	content: "\64"; }

.icon-minus:before {
	content: "\65"; }

.icon-menu:before {
	content: "\66"; }

.icon-mail:before {
	content: "\67"; }

.icon-kontakt:before {
	content: "\69"; }

.icon-facebook:before {
	content: "\6a"; }

.icon-doppelpfeil:before {
	content: "\6b"; }

.icon-close:before {
	content: "\6c"; }

.icon-pfeil-link-inactive:before {
	content: "\6d"; }

.icon-papierflieger-inactive:before {
	content: "\6e"; }

.icon-linked-in:before {
	content: "\68"; }

.icon-pfeil-unten-2:before {
	content: "\70"; }

.icon-play:before {
	content: "\71"; }

.icon-rocket:before {
	content: "\72"; }

.icon {
	font-size: 40px;
	height: 40px;
	float: left;
	line-height: 40px;
	text-decoration: none !important;
	&.rotate-left {
		transform: rotate(90deg); }
	&.rotate-right {
		transform: rotate(-90deg); } }

.video-icon {
	transform: translateY(-50%) translateX(-50%);
	position: absolute;
	top: 50%;
	@include font-size(90);
	height: 90px;
	left: 50%;
	color: #fff; }
