body {
	background-color: $gray; }

.user-highlight,.navbar-top-links a, .top-navigation .nav > li > a {
	color: rgba(255,255,255,0.6);
	transition: color .2s ease;
	&:hover {
		transition: color .2s ease;
		color: rgba(255,255,255,1); } }
.expandcontent {
	display: block; }
.peakcontent {
	display: none; }
.hide-content {
	.expandcontent {
		display: none; }
	.peakcontent {
		display: block; } }
.Iconbox textarea {
	height: 8em !important; }
.background-contain {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain; }
.background-cover {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover; }
.position-cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; }

.responsive-width {
	max-width: 100% !important;
	height: auto; }
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; }
.display-block {
	display: block; }
.dropzone {
	min-height: 300px;
	&.active {
		background-color: #f9f9fb; } }

.navbar-top-links {
	&.white-color {
		color: rgba(255,255,255,0.6) !important;
	 .nav > li > a {}
		color: rgba(255,255,255,0.6) !important; } }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
	background-color: transparent; }
.page-title {
	font-size: 28px;
	line-height: 34px;
	color: #fff;

	font-weight: 300;
	display: block;
	margin-top: 6px;
	margin-left: 15px; }
label {
	font-size: 12px;
	line-height: 18px;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-weight: normal; }
.btn:not(.btn-circle) {
	padding: 0 15px;
	min-width: 90px;
	vertical-align: middle;
	line-height: 33px;
	min-height: 35px;
	font-size: 13px; }
.hamburger {
	width: auto;
	float: left;
	height: auto;
	min-width: auto;
	margin-right: 0;
	margin-left: -53px;
	background-color: transparent;
	display: block !important;
	margin-top: 9px;

	.icon-bar {
		opacity: 0.6;
		background-color: #fff;
		display: block;
		width: 24px;
		margin-bottom: 3px;
		height: 2px;
		border-radius: 0;
		transition: opacity 0.2s ease; }

	&:hover, &:active {
		.icon-bar {
			transition: opacity 0.2s ease;
			opacity: 1; }
		background-color: transparent; }
	i {
		color: #fff;
		font-size: 20px; } }
.navbar-fixed-top, .navbar-static-top {
	padding: 50px 0 0 0;
	margin-bottom: 0;
	background-color: $skin-1-color !important;
	.navbar-collapse {
		padding-bottom: 36px; } }

.nav > li > a {
	font-weight: 300;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px; }

.nav.navbar-top-links a {
	font-size: 14px; }
.nav > li > a:hover, .nav > li > a:focus {
	background-color: transparent; }
.white-color {
	color: #fff; }
.top-navigation .nav > li > a {
	color: rgba(255,255,255,0.6); }
#mainNav {
	> li > a {
		color: rgba(255, 255, 255, 0.6); }
	.nav-tabs {
		border-bottom: 0px;
		> li > a {
			color: rgba(255,255,255,0.6);
			&:hover {
				color: #fff; } } } }
.nav-tabs > li > a, .nav-tabs > li > a:hover,.nav-tabs > li.active > a:focus {
	border: 0px; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border: 0px;
	border-radius: 0px;
	border-bottom: 5px solid #fff; }

.btn:active, .btn.active {
	box-shadow: none;
	outline: 0; }

.well {
   background-color: transparent;
   border: 0;
   box-shadow: none; }

.table {
	td, th {
		padding: 16px 12px !important; }
	th {
		font-weight: 400;
		text-transform: uppercase; } }
.imagePlaceHolder {
	padding: 30px;
	border: 1px dashed #e5e5e5;
	background: transparent;
	color: #666;
	z-index: 100;
	text-align: center;
	top: 0;
	left: 15px;
	right: 15px;
	cursor: pointer;
	bottom: 0;
	opacity: 1; }

.Iconbox--icon {
	min-height: 250px; }
.hasImage ~ .imagePlaceHolder {
	opacity: 0;
	transition: opacity .2s ease;
	position: absolute;
	color: #fff;
	text-shadow: 0px 0px 1px  #000;
	&:hover {
		opacity: 1;
		transition: opacity .2s ease; } }

img ~ .box-actions {
	left: 15px;
	right: 15px;
	width: auto; }

.Imagebox--picture {
	min-height: 260px;
	position: relative; }
.tabs-container .panel-body {
	background: transparent;
	border-color: transparent;
	border-radius: 0; }

.tabs-container .nav-tabs {
	border-bottom-color: $contrast-gray;

	li {
		a {
			color: #666; }
		&.active {
			a, & a:hover, & a:focus {
				border-color: #489be0;
				background: transparent;
				color: #4091D2 !important; } } } }

.tinymagic {
	font-family: "ProximaNova-Regular", Helvetica, Roboto, Arial, sans-serif;
	font-size: 16px;
	color: #47494a;
	line-height: 1.52;
	&.h2-s {
		line-height: 1.1;
		font-size: 34px;
		color: #9c3489;
		margin-bottom: 24px;
		margin-top: 24px; }
	&.p-s {} }
.Imagebox {

	&:nth-child(odd) {
		.Imagebox--picture {
			float: right; } } }
.Project {
	margin-bottom: 40px;
	padding-bottom: 90px;
	float: left;
	width: 100%;
	border-bottom: 1px solid #e7eaec; }

.MediaGallery {
	position: fixed;
	width: 80vw;
	z-index: 99999999;
	background-color: #fff;
	top: 50px;
	left: 10vw;
	right: 0;
	bottom: 40px;
	padding: 40px;
	overflow-y: scroll; }
.MediaGalleryOverlay {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999999999; }
.Imagebox--picture {
	img {
		max-width: 100%; } }
.Project {
	img {
		max-width: 100%; } }

.select2-container {
	width: 100% !important; }

.sortable-ghost {
  background: rgba(0,0,0, 0.1);
  border: 2px dashed #222; }
.tinymagic {
	background-color: #f6f6f6;
	padding: 9px 20px; }
.box {
	margin: 30px 0;
	background-color: #fff;
	margin: 30px 0;
	padding: 70px 30px 30px 30px; }

.form-horizontal .form-group {
	position: relative; }

.box-actions {
	z-index: 101;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px 30px;
	background-color: rgb(244, 244, 244); }
.box-actions--button-drag {
	i {
		color: #6f6f6f; } }
.feed-element {
	.image {
		width: 60px;
		overflow: hidden;
		height: 60px;
		img {
			max-height: 100%; } } }
.toolbar {
	margin-bottom: 50px; }

.Actionbar {
	height: 75px;
	width: 100%;
	float: left;
	padding: 20px 0;
	background-color: #f4f4f4;
	position: fixed;
	bottom: 0;
	z-index: 999;
	.btn {
		margin-right: 20px; } }
.table td, .table th {
	padding: 19px 12px !important; }
.table th {
	color: #aaa; }
.table {
	tbody {
		tr {
			td:first-of-type {
				a {
					width: 300px;
					float: left; } } } } }

.top-navigation .nav-tabs a {
	padding: 15px 24px; }

.box-actions label {
	margin-right: 10px;
	input[type=checkbox] {
		float: left;
		margin-left: 0px;
		margin-right: 4px;
		margin-top: 2px; } }

.GridSettings--item-colors.active {
	box-shadow: inset 0 0 38px rgba(0, 0, 0, 0.68); }


.ImageSelect {
	.box {
		float: left;
		display: block;
		width: 100%;
		margin-top: 0; } }

.ImageWrap {
	position: relative;
	display: block; }
.feed-activity-list {}


.NewsWidget--thumbnailwrap {
	img {
		max-width: 100%; } }
.GridSettings--reversed {
	transform: translateY(30px) !important; }

.tagcontainer {
	.textlist--editor {
		display: none; }
	.Iconbox {
		width: 24%;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-right: 5px;
		padding: 38px 30px 0px 30px;
		.Iconbox__text {
			padding: 0; } }
	.textlist--title {
		display: none; }
	.box-actions {
		padding: 4px 11px; } }

.Label--title {
	z-index: 999; }
