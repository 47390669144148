// Font
$font-stack: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$skin-1-color: #3a94e0;

// Basic Colors
$navy: $skin-1-color;
$navy-btn: #489be0;      // Primary color
$dark-gray: #c3c3c3;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warrning color
$red: #ED5565;        // Danger color
$contrast-gray: #e5e5e5;
// Various colors
$text-color: #666;   // Body text
$gray: #f9f9fb;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badget
$label-badget-color: #5E5E5E;
$light-blue: #f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color:  #ddd;   // IBox border
$ibox-title-bg: #ffffff;     // IBox Background header
$ibox-content-bg: #ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 0;

//Navigation
$nav-bg: #2F4050;
$nav-profil-pattern: url("patterns/header-profile.png") no-repeat;
$nav-text-color: #a7b1c2;
