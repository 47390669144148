/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
 */
@import "frontend/typohelp";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Variables, Mixins
@import "backend/variables";
@import "frontend/variables";

@import "backend/mixins";

// INSPINIA Theme Elements
@import "backend/typography";
@import "backend/navigation";
@import "backend/top_navigation";
@import "backend/buttons";
@import "backend/badgets_labels";
@import "backend/elements";
@import "backend/sidebar";
@import "backend/base";
@import "backend/pages";
@import "backend/chat";
@import "backend/metismenu";
@import "backend/spinners";

// INSPINIA Skins
@import "backend/skins";

// Media query style
@import "backend/media";

// Custom style
// Your custom style to override base style
@import "backend/custom";


@import "frontend/helpers";

@import "frontend/icons";
@import "frontend/custom";
@import "frontend/team";

